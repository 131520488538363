import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import {
  createAdminAPIRequest,
  getAdminListAPIRequest,
} from "./AdministrationAPI";

export interface CounterState {
  isInformationFetching: boolean;
  isCreateFetching: boolean;
  createStatus: "IDLE" | "PENDING" | "SUCCESS" | "FAILED";
  informationFetchingStatus: "IDLE" | "PENDING" | "SUCCESS" | "FAILED";
  response: [];
  createResponse: {};
}

export interface CreateStatusProps {
  id?: number;
  firstName?: string;
  email?: string;
  username?: string;
  password?: string;
  status?: string;
}

const initialState: CounterState = {
  isInformationFetching: false,
  informationFetchingStatus: "IDLE",
  response: [],
  isCreateFetching: false,
  createStatus: "IDLE",
  createResponse: {},
};

export const getAdminListRequest = createAsyncThunk(
  "admin/getAdmin",
  async (id: number) => {
    return await getAdminListAPIRequest(id);
  }
);

export const createAdminRequest = createAsyncThunk(
  "admin/createAdmin",
  async (data: CreateStatusProps) => {
    return await createAdminAPIRequest(data);
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    clearAdmin: (state) => {
      state.isInformationFetching = false;
      state.informationFetchingStatus = "IDLE";
      state.response = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminListRequest.pending, (state) => {
        state.informationFetchingStatus = "PENDING";
        state.isInformationFetching = true;
      })
      .addCase(getAdminListRequest.fulfilled, (state, action: any) => {
        // Add user to the state array
        state.informationFetchingStatus = "SUCCESS";
        state.isInformationFetching = false;
        state.response = action.payload;
      })
      .addCase(getAdminListRequest.rejected, (state) => {
        state.informationFetchingStatus = "FAILED";
        state.isInformationFetching = false;
      })
      .addCase(createAdminRequest.pending, (state) => {
        state.createStatus = "PENDING";
        state.isCreateFetching = true;
      })
      .addCase(createAdminRequest.fulfilled, (state, action: any) => {
        // Add user to the state array
        state.createStatus = "SUCCESS";
        state.isCreateFetching = false;
        state.createResponse = action.payload;
      })
      .addCase(createAdminRequest.rejected, (state) => {
        state.createStatus = "FAILED";
        state.isCreateFetching = false;
      });
  },
});

export const adminSelector = (state: RootState) => state.admin;

export const { clearAdmin } = adminSlice.actions;

export default adminSlice.reducer;
