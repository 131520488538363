import { authenticatedRequest } from "../../../../utils/axios/commonAxios";

interface ValidatorProps {
  id?: number;
  firstName?: string;
  email?: string;
  username?: string;
  password?: string;
  status?: string;
}

export const createAdminAPIRequest = async (data: ValidatorProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/admin`,
    "post",
    {
      data: {
        firstName: data?.firstName,
        username: data?.username,
        email: data?.email,
        password: data?.password,
        status: data?.status,
      },
    }
  );
};

export const getAdminListAPIRequest = async (id: number) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${id}/admins`,
    "get"
  );
};
